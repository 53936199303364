<template>
  <div class="d-flex flex-column fill-height">
    <div id="map" class="map" ref="map">
    </div>
    <div>
      <v-select
        v-model="selected"
        :items="devicesSelectedArray"
        item-text="name"
        item-value="id"
        label="Seleccionar unidad"
        single-line
        clearable
        class="pl-1 pr-1"
        @change="loadItemsTable"
      ></v-select>
    </div>
    <div>
      <!-- <RecycleScroller
        class="scroller"
        :items="itemsTable"
        :item-size="24"
        key-field="id"
        v-slot="{ item }"
      >
        <div class="td">
          <v-chip
            :color="devicesSelected[item.deviceId].color"
            dark
            label
            small
          >
            {{ devicesSelected[item.deviceId].name }}
          </v-chip>
        </div>
        <div class="td">
          {{ item.serverTime | datetime }}
        </div>
        <div class="td">
          {{ eventsType[item.type] ? eventsType[item.type].name : '' }}
        </div>
      </RecycleScroller> -->
      <v-simple-table height="300" dense fixed-header>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
                Unidad
              </th>
              <th class="text-left">
                Tipo
              </th>
              <th class="text-left">
                Ubicación
              </th>
              <th>
                <div class="d-flex justify-space-between">
                  <span style="align-self: center">Fecha y Hora</span>
                  <v-btn
                    v-if="itemsTable && itemsTable.length"
                    color="success"
                    @click="toExcel"
                    icon
                  >
                    <v-icon>mdi-file-excel</v-icon>
                  </v-btn>
                </div>
              </th>
            </tr>
          </thead>
            <tbody
              v-hotkey="keymap"
            >
              <tr
                style="cursor: pointer;"
                :class="{ active: index === selectedIndex }"
                v-for="(item, index) in itemsTable"
                :key="item.id"
                @click="selectCoordinate(item)"
              >
                <td>
                  <v-chip
                    :color="devicesSelected[item.deviceId].color"
                    dark
                    label
                    small
                  >
                    {{ devicesSelected[item.deviceId].name }}
                  </v-chip>
                </td>
                <td>{{ eventsType[item.type] ? eventsType[item.type].name : '' }}</td>
                <td>{{ item.attributes && item.attributes.position ?
                    item.attributes.position.longitude + ', ' + item.attributes.position.latitude : '' }}
                </td>
                <td>{{ item.serverTime | datetime }}</td>
              </tr>
            </tbody>
        </template>
      </v-simple-table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import _ from 'underscore'
import Dialog from '@/components/dialog'
import Form from '@/components/form'
import ApiService from '@/util/api.service'
import arrow from '@/assets/map/arrow.svg'
import { periods } from '@/util'
import ExportToExcel from '@/util/json_to_excel.service'
import { eventsType } from './data'

const ol = window.ol

export default {
  name: 'EventsReport',
  props: {
    devices: Array,
    groups: Array,
  },
  data() {
    return {
      map: null,
      initZoom: 12,
      events: [],
      itemsTable: [],
      devicesSelected: {},
      selected: null,
      selectedIndex: null,
      eventsType,
      mapColors: [
        '#F06292',
        '#BA68C8',
        '#4DD0E1',
        '#4DB6AC',
        '#FF8A65',
        '#A1887F'
      ],
      filtro: {
        deviceId: [6310],
        type: ['allEvents'],
        from: new Date(),
        fromHour: '00:00',
        to: new Date(),
        toHour: '23:59',
      },
      filtersForm: {
        deviceId: {
          label: 'Unidades',
          type: Array,
          options: this.devices,
          multiple: true,
          optional: true
        },
        'deviceId.$': {
          type: String,
          blackbox: true,
        },
        groupId: {
          label: 'Grupos',
          type: Array,
          options: this.groups,
          multiple: true,
          optional: true
        },
        'groupId.$': {
          type: String,
          blackbox: true,
        },
        type: {
          label: 'Tipo',
          type: String,
          multiple: true,
          options: _.toArray(eventsType)
        },
        periodo: {
          label: 'Periodo',
          type: String,
          options: periods,
          onChange: (val, fields) => {
            fields.from_separator.setVisibility(val === 'custom')
            fields.from.setVisibility(val === 'custom')
            fields.fromHour.setVisibility(val === 'custom')
            fields.to_separator.setVisibility(val === 'custom')
            fields.to.setVisibility(val === 'custom')
            fields.toHour.setVisibility(val === 'custom')
          }
        },
        from_separator: {
          label: 'Desde',
          type: String,
          separator: true,
          optional: true
        },
        from: {
          label: 'Fecha',
          type: Date,
          visibility: false
        },
        fromHour: {
          label: 'Hora (HH:mm)',
          type: String,
          visibility: false,
          time: true
        },
        to_separator: {
          label: 'Hasta',
          type: String,
          separator: true,
          optional: true
        },
        to: {
          label: 'Fecha',
          type: Date,
          visibility: false
        },
        toHour: {
          label: 'Hora (HH:mm)',
          type: String,
          visibility: false,
          time: true
        }
      }
    }
  },
  computed: {
    ...mapGetters('auth', ['getUser']),
    devicesSelectedArray() {
      return _.toArray(this.devicesSelected);
    },
    keymap() {
      return {
        up: () => {
          if (this.selectedIndex > 0) {
            this.selectCoordinate(this.itemsTable[this.selectedIndex - 1])
          }
        },
        down: () => {
          if (this.selectedIndex != null && this.selectedIndex + 1 <= this.itemsTable.length) {
            this.selectCoordinate(this.itemsTable[this.selectedIndex + 1])
          }
        }
      }
    }
  },
  mounted() {
    this.map = new ol.layer.Tile({
      source: new ol.source.XYZ({
        url: '//mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}'
      })
    })
    this.satelliteMap = new ol.layer.Tile({
      source: new ol.source.XYZ({
        url: '//mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}&s=Ga'
      })
    })
    this.satelliteMap.setVisible(false)
    this.darkmodeMap = new ol.layer.Tile({
      source: new ol.source.XYZ({
        // url: '//api.maptiler.com/maps/a881ff38-8ab2-49ee-baae-c8ac0a1ede8f/256/{z}/{x}/{y}.png?key=G3BcWuok730ChWrFiN06'
        url: '//api.maptiler.com/maps/6dea3d11-51b5-48e9-997b-ebba1fdfefe4/256/{z}/{x}/{y}.png?key=G3BcWuok730ChWrFiN06'
      })
    })
    this.darkmodeMap.setVisible(this.mapMode === 'night')

    this.mapView = new ol.View({
      center: this.getMapCenter || ol.proj.fromLonLat([
        this.getUser.longitude, this.getUser.latitude
      ]),
      zoom: this.initZoom
    })

    this.pointsSource = new ol.source.Vector()
    this.pointsLayer = new ol.layer.Vector({
      source: this.pointsSource
    });

    this.olmap = new ol.Map({
      target: 'map',
      layers: [
        this.map,
        this.darkmodeMap,
        this.satelliteMap,
        this.pointsLayer
      ],
      view: this.mapView
    })
    this.olmap.on('pointermove', (e) => {
      if (e.dragging) {
        return;
      }
      const pixel = this.olmap.getEventPixel(e.originalEvent);
      const hit = this.olmap.hasFeatureAtPixel(pixel);
      this.olmap.getTargetElement().style.cursor = hit ? 'pointer' : '';
    });

    this.olmap.on('click', (e) => {
      let trigered = false
      this.olmap.forEachFeatureAtPixel(e.pixel, (feature) => {
        if (!trigered) {
          this.selected = parseInt(feature.getId())
        }
        trigered = true
      })
    });
  },
  methods: {
    async load() {
      window.VMA.loading(true)
      const data = {
        deviceId: this.filtro.deviceId,
        groupId: this.filtro.groupId,
        type: this.filtro.type,
        ...this.period
      }
      try {
        this.events = await ApiService({
          url: '/reports/events',
          method: 'get',
          params: data
        })
        const devicesSelected = {}
        const devicesId = _.unique(this.events.map((event) => event.deviceId))
        let colorCounter = 0
        devicesId.forEach((deviceId) => {
          const device = _.findWhere(this.devices, { id: deviceId })
          if (device) {
            devicesSelected[deviceId] = { ...device }
            devicesSelected[deviceId].color = this.mapColors[colorCounter]
            colorCounter++
            if (colorCounter === this.mapColors.length) {
              colorCounter = 0
            }
          }
        })
        this.devicesSelected = devicesSelected
        this.loadItemsTable()
      } catch (e) {
        console.error(e)
        window.VMA.showError({ title: 'Ocurrió un error al cargar los datos' })
      } finally {
        window.VMA.loading(false)
      }
    },
    filtrar() {
      const form = new Form({
        schema: this.filtersForm,
        item: this.filtro
      })
      const dialog = new Dialog({
        title: 'Filtrar rutas',
        actions: [{
          help: 'Filtrar',
          icon: 'mdi-filter',
          color: 'secondary',
          action: async () => {
            if (form.hasErrors()) {
              return
            }
            this.selected = null
            const item = form.getItem()
            this.filtro = item
            if (item.periodo !== 'custom') {
              this.period = form.fields.periodo.getOption().getPeriod()
            } else {
              this.period = form.fields.periodo.getOption().getPeriod({
                ..._.pick(item, 'from', 'to', 'fromHour', 'toHour')
              })
            }
            this.load()
            dialog.close()
          }
        }]
      })
      dialog.open()
      dialog.append(form)
    },
    loadItemsTable() {
      this.itemsTable = this.selected ? this.events.filter((event) => event.deviceId === this.selected) : this.events
      this.pointsSource.clear()
      this.selectedIndex = null
      const unidades = _.groupBy(this.itemsTable, 'deviceId')
      Object.keys(unidades).forEach((key) => {
        unidades[key].forEach((item) => {
          if (item && item.attributes && item.attributes.position) {
            const circleFeature = new ol.Feature({
              geometry: new ol.geom.Point(ol.proj.fromLonLat([item.attributes.position.longitude, item.attributes.position.latitude])),
            });
            circleFeature.setId(item.id)
            circleFeature.setStyle(new ol.style.Style({
              image: new ol.style.Icon({
                src: arrow,
                rotation: item.attributes.position.course,
                color: this.devicesSelected[item.deviceId].color,
                scale: 0.8
              })
            }))
            this.pointsSource.addFeature(circleFeature)
          }
        })
      })
    },
    selectCoordinate(item) {
      if (item.attributes && item.attributes.position) {
        this.mapView.setZoom(16)
        this.mapView.setCenter(ol.proj.fromLonLat([item.attributes.position.longitude, item.attributes.position.latitude]))
      }
      this.selectedIndex = this.itemsTable.findIndex((it) => it.id === item.id)
    },
    toExcel() {
      ExportToExcel(
        'Eventos de unidades '
          + moment(this.period.from).format('DD-MM-YYYY HH mm') + ' a '
          + moment(this.period.to).format('DD-MM-YYYY HH mm'),
        this.itemsTable.map((item) => ({
          Unidad: this.devicesSelected[item.deviceId].name,
          Tipo: eventsType[item.type] ? eventsType[item.type].name : '',
          Ubicación: item.attributes && item.attributes.position
            ? item.attributes.position.longitude + ', ' + item.attributes.position.latitude : '',
          'Fecha y Hora': moment(item.serverTime).format('DD-MM-YYYY HH:mm')
        }))
      )
    },
  }
}
</script>

<style lang="scss">
  .map {
    height: 100%;
    width: 100%;
  }
</style>
